  <!-- v-if="item.role === this.$store.getters.GetUserData.user_roles[1]" -->

<!-- (dataParsed.user_roles).some(item => roles.includes(item)) -->
<template>
  <li
   v-if=" userRoles ?(item.roles).some(i=>( userRoles).includes(i)) : null "
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import {Decrypt} from '../../../../../../../store/modules/Auth/encrypt.js'
export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mounted(){
    let accessToken = Decrypt()
        let jwtData = accessToken.split('.')[1]
        let decodedJwtJsonData = JSON.parse(window.atob(jwtData))

 this.userRoles = decodedJwtJsonData.roles

  },
  data(){
    return{
      userRoles:null,
    }
  },

  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
