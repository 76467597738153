export default [
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    roles: ['Admin', 'Company', 'User']
  },

  {
    title: 'المشرفين',
    route: 'admins',
    icon: 'UserPlusIcon',
    roles: ['Admin']
  },
  {
    title: 'المندوبين',
    route: 'agents',
    icon: 'UserIcon',
    roles: ['Admin']
  },
  {
    title: 'أماكن الخدمه',
    route: 'service-places',
    icon: 'ServerIcon',
    roles: ['Admin']
  },
  {
    title: 'الشركات',
    route: 'companies',
    icon: 'ShoppingBagIcon',
    roles: ['Admin']
  },
  {
    title: 'الخزنة',
    route: 'safe',
    icon: 'DatabaseIcon',
    roles: ['Admin']
  },
  {
    title: 'التقرير المجمع',
    route: 'full-report',
    icon: 'FileIcon',
    roles: ['Admin']
  },
  {
    title: 'بيانات التقرير',
    route: 'report-items',
    icon: 'FileIcon',
    roles: ['Admin']
  },
  {
    title: 'الشحنات المفرج عنها',
    route: 'released-shipments',
    icon: 'FileIcon',
    roles: ['Admin']
  },
  {
    title: 'التقرير ',
    route: 'company-report',
    icon: 'FileIcon',
    roles: ['Company']
  },
  {
    title: 'التقرير',
    route: 'agent-report',
    icon: 'FileIcon',
    roles: ['User']
  },
  {
    title: 'القسايم',
    route: 'coupons',
    icon: 'FileIcon',
    roles: ['User']
  },
  {
    title: 'المصاريف',
    route: 'expenses',
    icon: 'FileIcon',
    roles: ['User']
  },
  {
    title: 'العهدة',
    route: 'custody',
    icon: 'FileIcon',
    roles: ['User']
  },
  {
    title: 'الفواتير',
    route: 'invoices',
    icon: 'FileIcon',
    roles: ['Admin']
  },
  {
    title: 'المنتجات',
    route: 'products',
    icon: 'FileIcon',
    roles: ['Admin']
  },
  {
    title: 'الضرائب',
    route: 'taxes',
    icon: 'FileIcon',
    roles: ['Admin']
  },
  {
    title: 'البوالص',
    route: 'billOfLandings',
    icon: 'FileIcon',
    roles: ['Admin']
  }
]
